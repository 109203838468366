import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import Spacing from '../../components/partials/Spacing'
import Form from '../../components/forms/Form'

import { getCookieByName } from '../../helpers/getCookieByName'

const DataGovernanceKafka = () => {
  const data = useStaticQuery(graphql`
    query Data_Governance_Kafka_SEO {
      allContentfulPages(
        filter: {
          pageReference: {
            pageIdentifier: { eq: "Governance_Whitepaper_Form_Page" }
          }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
      allContentfulAsset(
        filter: { id: { eq: "f17915f7-fd84-5f2f-ba2a-21c2cda296d0" } }
      ) {
        edges {
          node {
            id
            file {
              url
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const onClick = () => {
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
    document.querySelector<HTMLInputElement>('#formsubmit').disabled = true
  }

  return (
    <Layout
      newsletter={false}
      hasBanner={true}
      logo={'viseca_logo'}
      onlyLogo={true}
    >
      <HeaderSEO pageSEO={pageSEO} />

      <section
        className="overflow-hidden container-1 hero-margin"
        style={{ marginTop: '110px' }}
      >
        <div className="text-center">
          <h2 className="h4-like pb-0">WhitePaper</h2>
          <div className="d-flex flex-wrap justify-content-center">
            <h1 className="pb-0" style={{ lineHeight: '1.4em' }}>
              Real-time Data Governance
              <br />A reference architecture for governing with DataOps
              principles
            </h1>
          </div>
        </div>

        <div className="my-3 py-3"></div>
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-6 col-10 col-md-9 pt-5">
              <div className="lg-reset-padding ">
                <img
                  className="w-75"
                  src="//images.ctfassets.net/tnuaj0t7r912/389nOSOkLZB366MTFBUcMN/1a01093acb51d4e267a495fe76de5101/large-governacewhitepaper_preview__1_.png"
                  alt="Real-time Data Governance"
                />
                <div className="mobile-margin-reset">
                  <p>
                    DataOps is emerging as a new practice for working with data,
                    bringing new data governance challenges with it. This paper
                    outlines a new data governance definition, an accompanying
                    set of DataOps principles and reference architecture for
                    real-time apps.
                  </p>
                  <p>
                    The architecture outlined has enabled Swiss financial
                    services provider Viseca to comply with stringent
                    regulations in a dynamic market; but it also fast-tracked
                    their <b>Apache Kafka</b> project to production,{' '}
                    <b> reducing time-to-market of streaming apps by 10x.</b>
                  </p>

                  <p>you&apos;ll learn:</p>
                  <p>
                    <i className="fa fa-check box-green-check pr-2"></i>
                    What is data governance today, and why is it changing? How
                    can it impact your data projects?
                    <br />
                    <br />
                    <i className="fa fa-check box-green-check pr-2"></i>
                    How can you deliver an enterprise-wide real-time data
                    platform service?
                    <br />
                    <br />
                    <i className="fa fa-check box-green-check pr-2"></i>
                    What practical techniques can you use to build data access
                    models with technologies such as Apache Kafka?
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className="px-0 col-lg-4 offset-lg-2 offset-0 col-sm-7 col-10 pt-5 mobile-margin-bottom">
              <div className="footer-color py-5 px-2 rounded">
                <div className="d-flex flex-wrap">
                  <div className="col-md-12 mobile-padding-reset">
                    {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                    <Form
                      formId="zoominfo-form-with-phone"
                      action="https://go.pardot.com/l/877242/2020-09-22/4g6js4"
                      onSubmit={onClick}
                      inputId="formsubmit"
                      inputValue="Download Now"
                      firstName={'required'}
                      lastName={'required'}
                      email={'required'}
                      message={'notRequired'}
                      messageValue={'Tell us about your usecase'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
    </Layout>
  )
}
export default DataGovernanceKafka
